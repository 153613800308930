/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
/* End of reset css */
input:focus, button:focus, textarea:focus, select:focus {
     outline-color: #FF7900;
}


html, body {
    width: 100vw;
    height: 100%;
    background-color: #EEEEEE;
    max-width: 100%;
    font-size: 6px;
    font-family: HelvNeueOrange, helvetica, arial, sans-serif;
}

/*
 * This beautiful piece of code means we're
  scaling our whole page based on REM
  from 6 to 10px for screen sizes from 320 to 400px
 */
@media screen and (min-width: 320px) {
    html, body {
        font-size: calc(6px + 4 * ((100vw - 320px) / 80));
    }
}
@media screen and (min-width: 400px) {
    html, body {
        font-size: 10px;
    }
}

#root {
    display: flex;
    justify-content: center;
    background-color: #EEEEEE;
    height: 100%;
}

@font-face {
    font-family: HelvNeueOrange;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local("Helvetica 55 Roman"), local("HelveticaNeue"),
    url("./fonts/HelvNeue55_W1G.woff2") format("woff2"),
    url("./fonts/HelvNeue55_W1G.woff2") format("woff");
}

@font-face {
    font-family: HelvNeueOrange;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local("Helvetica 75 Bold"), local("HelveticaNeue-Bold"),
    url("./fonts/HelvNeue75_W1G.woff2") format("woff2"),
    url("./fonts/HelvNeue75_W1G.woff2") format("woff");
}
