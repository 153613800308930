.heading-container {
    display: grid;
    grid-template-areas: 'image title .';
    grid-template-columns: 70px 1fr 15px;
    grid-template-rows: auto;
    max-width: 100%;
    background: white;
    margin-bottom: 1.5%;
}

.image-container {
    grid-area: image;
}

.title-container {
    grid-area: title;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 3rem;
}

.image {
    padding: 15px;
    width: calc(100% - 30px);
}

.title {
    margin: 0;
    text-align: end;
    font-weight: 700;
    white-space: pre-line;
}

.content {
    padding: 20px;
}

.privacy-notice-box {
    text-align: left;
    width: 100%;
    margin: auto;
    background: white;
    padding-bottom: 5%;
    font-size: 1.5rem;
}

.privacy-notice-box table,
.privacy-notice-box th,
.privacy-notice-box td {
    border: solid 1px;
    margin: auto;
}

.privacy-notice-box table {
    width: 90%;
}

.plain-text {
    display: block;
    text-align: left;
    vertical-align: middle;
    padding: 5%;
}

.privacy-notice-box th {
    text-align: center;
    font-weight: bold;
    vertical-align: middle;
    padding: 1%
}

.privacy-notice-box h1 {
    margin: 0.5% auto;
    font-weight: bold;
    padding: 1% 5% 1%;
    font-size: 2rem;
}

.privacy-notice-box h2 {
    margin: 0.5% auto;
    font-weight: bold;
    padding: 1% 5% 1%;
    font-size: 2rem;
    color: #FF7900;
}

.privacy-notice-box p {
    padding: 1% 5%;
    margin: auto;
    text-align: justify;
    text-justify: inter-word;
}

.privacy-notice-box ul {
    list-style-type: disc;
    padding-top: 2.5%;
    padding-left: 15%;
    padding-right: 5%;
}

.privacy-notice-box li {
    list-style-type: disc;
    padding-right: 2.5%;
    padding-bottom: 2.5%;
}
